
// @ts-nocheck
/* eslint-disable */
import '@babel/polyfill';
import axios from 'axios';
import { addDrug } from './addDrug';
import { login, logout } from './login';
import { Comment } from './comment';
import { signup } from './signup';
import { updateSettings } from './updateSettings';
import { showAlert } from './alerts';
import { resetPassword } from './resetPassword';
import { resetPasswordWithToken } from './resetPasswordWithToken';
import { changeStatus } from './changeStatus';
import { deleteDrug } from './deleteDrug';
import { editDrug } from './editDrug';
import { deleteComment } from './deleteComment';
import { editComment } from './editComment';
import { editUser } from './editUser';
import { contactEmail } from './sendContactEmail';
import { generateArticle } from "./loadArticle";
import { configureHeaderAlphabetNav } from './navbarAlphabets';
import { fetchDrugNews, arrangeOrders } from './drugClient';
import { setNavigation, retrieveNavigation } from './setNavigationLocalStorage';
import { setSeeMoreContent, boldSearchQuery } from './searchClient';
import { setBannerRadioBtn} from './overviewClient';
import { setTooltipBehavior } from './setTooltipHover';
import { initializeQueryAssistant } from './initializeQueryAssistant';
//import { queryAssistant } from './queryAssistant';

// DOM ELEMENTS
const loginForm = document.querySelector('.form--login');
const resetForm = document.querySelector('.form--resetpassword');
const resetFormWithToken = document.querySelector(
  '.form--resetpasswordwithtoken'
);
const signupForm = document.querySelector('.form--signup');
const searchForm = document.querySelector('.nav-searchbar'); //|| document.querySelector('.nav-searchbar-main') || document.querySelector('.nav-searchbar-other');
const logOutBtn = document.querySelector('.nav__el--logout');
const commentBtn = document.querySelector('.form--comment');
const userDataForm = document.querySelector('.form-user-data');
const userPasswordForm = document.querySelector('.form-user-password');
const tablinks = document.getElementsByClassName('tablinks');
const tabcontent = document.getElementsByClassName('tabcontent');
const indicationAddBtn = document.querySelector('.btn--addIndication');
const indicationRemoveBtn = document.querySelector('.btn--removeIndication');
const systemAddBtn = document.querySelector('.btn--addSystem');
const systemRemoveBtn = document.querySelector('.btn--removeSystem');
const drugAddBtn = document.querySelector('.btn--addDrugName'); // Multiple brands update
const drugRemoveBtn = document.querySelector('.btn--removeDrugName'); // Multiple brands update
const documentAddBtn = document.querySelector('.btn--addDocument');
const documentRemoveBtn = document.querySelector('.btn--removeDocument');
const drugForm = document.querySelector('.form--createdrug');
const indications = document.querySelector('.indications');
const systems = document.querySelector('.systems');
const drugs = document.querySelector('.drugnames'); // Multiple brands update
const documents = document.querySelector('.documents');
const editDrugStatus = document.getElementById('editdrug');
const editComments = document.getElementById('comments');
const editUsers = document.getElementById('users');
const contactForm = document.querySelector('.contact-form');
const contactFormReset = document.querySelector('.contact-form-reset');

const statuses = document.getElementsByClassName('edit_status');
const comments = document.getElementsByClassName('edit_comment');
const users = document.getElementsByClassName('edit_user');

const deleteDrugs = document.getElementsByClassName('delete_drug');
const deleteComments = document.getElementsByClassName('delete_comment');

const editDrugForm = document.querySelector('.form--editdrug');

const dropdownBtn = document.querySelector('.dropbtn');

const dropdownBtn2 = document.querySelector('.dropbtn2');
const dropdownContent2 = document.querySelector('.dropdown-content2');

const carousel = document.querySelector('.carousel');
const carouselSlide1 = document.getElementById('img-1');
const carouselSlide2 = document.getElementById('img-2');

// const createDOMPurify = require('dompurify');
// const { JSDOM } = require('jsdom');
// const window = (new JSDOM('')).window;
// const DOMPurify = createDOMPurify(window); 

if (carousel) {
  let num = 0;
  setInterval(function () {
    num = (num + 1) % 2

    if (num == 1) {
      carouselSlide1.checked = true;
      carouselSlide2.checked = false;

    } else {
      carouselSlide1.checked = false;
      carouselSlide2.checked = true;
    }
  }, 8000);
}

/*if (dropdownContent2) {

  window.addEventListener('click',
    e => {


      if (dropdownContent2.style.display == 'block') {
        dropdownContent2.style.display = 'none';
      }
    }
  );

  dropdownBtn.addEventListener('click', e => {

    // hide the other dropdown
    const mobileCategoryDropdown = document.querySelector(".navbar-mobile-category-change");
    const mobileAlphabetDropdown = document.querySelector(".navbar-mobile-alphabets-dropdown");

    if (mobileCategoryDropdown != null && mobileAlphabetDropdown != null) {

      mobileCategoryDropdown.style.display = "none";
      mobileAlphabetDropdown.classList.toggle("navbar-mobile-alphabets-dropdown");
    }


  })
} */

if (dropdownContent2) {

  window.addEventListener('click',
    e => {


      if (dropdownContent2.style.display == 'block') {
        dropdownContent2.style.display = 'none';

      }
    }
  );


} 

if (dropdownBtn2) {

  dropdownBtn2.addEventListener("click", (event) => {
    

    if (dropdownContent2.style.display != "block") {
      dropdownContent2.style.display = "block";
  
    } else {
      dropdownContent2.style.display = "none";

    }

    event.stopPropagation();
  })
}

const pageTitle = document.title;

if (pageTitle.includes("Search Results")) {

  /*try {
    const navbarAlpha = document.querySelector(".navbar-alpha");
    navbarAlpha.innerHTML = "";
  
    const hrElement = document.createElement("hr"); 
    hrElement.classList.add("hr-style");
    navbarAlpha.appendChild(hrElement);
  } catch (error) {
    console.warn("Error Exist at line 178 at index.js...Issue unsolved")
  }*/
}
 
if (contactForm)
  contactForm.addEventListener('submit', e => {
    e.preventDefault();

    const first_name = document.getElementById('first_name').value;
    const contact_email = document.getElementById('contact_email').value;
    const msg = document.getElementById('question').value;
    if (grecaptcha && grecaptcha.getResponse().length !== 0) {
      console.log("Contact email called!");
      contactEmail(first_name, contact_email, msg);
      contactFormReset.reset();
    } else {
      showAlert('error', 'Check the recaptcha.');
    }


  });

if (editUsers) {
  Array.from(users).forEach(element => {
    element.addEventListener('click', e => {
      e.preventDefault();
      let targetId = e.currentTarget.parentNode.parentNode.id;

      editUser(targetId);

      let base_url = window.location.origin;

      if (e.currentTarget.src == `${base_url}/img/CheckMark.png`) {
        e.currentTarget.setAttribute('src', `${base_url}/img/Cancel.png`);
      } else {
        e.currentTarget.setAttribute('src', `${base_url}/img/CheckMark.png`);
      }
    });
  });
}

if (editComments) {
  Array.from(comments).forEach(element => {
    element.addEventListener('click', e => {
      e.preventDefault();
      let targetId = e.currentTarget.parentNode.parentNode.id;
      let drugId = e.currentTarget.parentNode.parentNode.class;

      editComment(targetId, drugId);

      let base_url = window.location.origin;

      if (e.currentTarget.src == `${base_url}/img/CheckMark.png`) {
        e.currentTarget.setAttribute('src', `${base_url}/img/Cancel.png`);
      } else {
        e.currentTarget.setAttribute('src', `${base_url}/img/CheckMark.png`);
      }
    });
  });

  Array.from(deleteComments).forEach(element => {
    element.addEventListener('click', e => {
      e.preventDefault();
      let targetId = e.currentTarget.parentNode.parentNode.id;
      let drugId = e.currentTarget.parentNode.parentNode.class;

      if (confirm('Are you sure you want to delete comment?')) {
        deleteComment(targetId, drugId);
        e.currentTarget.parentNode.parentNode.remove();
      }
    });
  });
}

if (editDrugForm)
  editDrugForm.addEventListener('submit', e => {
    e.preventDefault();
    //e.target.textContent = 'Processing...';
    const drugName = document.getElementById('drug_name').value;
    //const drugName = document.getElementsByClassName('drug_name');
    const chemicalName = document.getElementById('chemical_name').value;
    const dosageForm = document.getElementById('dosage_form').value;
    const approvalYear = document.getElementById('approval_year').value;
    const className = document.getElementById('class_name').value;
    const companyName = document.getElementById('company_name').value;
    const clinicalTrials = document.getElementById('clinical_trials').value;
    const indicationName = document.getElementsByClassName('indication_name');
    const systemName = document.getElementsByClassName('system_name');
    const documentTitles = document.getElementsByClassName('document_title');
    const documentLinks = document.getElementsByClassName('document_link');
    const documentYears = document.getElementsByClassName('document_year');
    const documentSources = document.getElementsByClassName('document_source');
    const documentTypes = document.getElementsByClassName('document_type');
    const documentMales = document.getElementsByClassName('document_male');
    const documentFemales = document.getElementsByClassName('document_female');
    const documentCounts = document.getElementsByClassName('document_count');
    const documentIds = document.getElementsByClassName('drug_document');
    const editedBy = document.getElementById('editedBy').value;

    let drugId = document.getElementsByClassName('form--editdrug');
    drugId = drugId[0].id;

    const indicationArray = Array.from(indicationName).map(el => el.value);
    const systemArray = Array.from(systemName).map(el => el.value);
    //const drugArray = Array.from(drugName).map(el => el.value);

    const documentIdsArray = Array.from(documentIds).map(el => el.id);
    const documentTitlesArray = Array.from(documentTitles).map(el => el.value);
    const documentLinksArray = Array.from(documentLinks).map(el => el.value);
    const documentYearsArray = Array.from(documentYears).map(el => el.value);
    const documentSourcesArray = Array.from(documentSources).map(
      el => el.value
    );
    const documentTypesArray = Array.from(documentTypes).map(el => el.value);
    const documentMalesArray = Array.from(documentMales).map(el => el.value);
    const documentFemalesArray = Array.from(documentFemales).map(el => el.value);
    const documentCountsArray = Array.from(documentCounts).map(el => el.value);

    let documentArray = [];

    for (let i = 0; i < documentTitlesArray.length; i++) {
      documentArray.push([
        documentTitlesArray[i],
        documentLinksArray[i],
        documentYearsArray[i],
        documentSourcesArray[i],
        documentTypesArray[i],
        documentIdsArray[i],
        documentMalesArray[i],
        documentFemalesArray[i],
        documentCountsArray[i]
        
      ]);
    }

    //console.log(documentArray);
    editDrug(
      drugName,
      chemicalName,
      dosageForm,
      clinicalTrials,
      approvalYear,
      indicationArray,
      companyName,
      className,
      systemArray,
      documentArray,
      drugId,
      editedBy
    );
  });

if (editDrugStatus) {
  Array.from(statuses).forEach(element => {
    element.addEventListener('click', e => {
      e.preventDefault();
      let targetId = e.currentTarget.parentNode.parentNode.id;
      changeStatus(targetId);

      let base_url = window.location.origin;

      if (e.currentTarget.src == `${base_url}/img/CheckMark.png`) {
        e.currentTarget.setAttribute('src', `${base_url}/img/Cancel.png`);
      } else {
        e.currentTarget.setAttribute('src', `${base_url}/img/CheckMark.png`);
      }
    });
  });

  Array.from(deleteDrugs).forEach(element => {
    element.addEventListener('click', e => {
      e.preventDefault();
      let targetId = e.currentTarget.parentNode.parentNode.id;

      if (confirm('Are you sure you want to delete drug?')) {
        deleteDrug(targetId);
        e.currentTarget.parentNode.parentNode.remove();
      }
    });
  });
}

if (drugForm)
  drugForm.addEventListener('submit', e => {
    e.preventDefault();
    //e.target.textContent = 'Processing...';
    //const drugName = document.getElementsByClassName('drug_name');
    const drugName = document.getElementById('drug_name').value;
    const chemicalName = document.getElementById('chemical_name').value;
    const dosageForm = document.getElementById('dosage_form').value;
    const approvalYear = document.getElementById('approval_year').value;
    const className = document.getElementById('class_name').value;
    const companyName = document.getElementById('company_name').value;
    const clinicalTrials = document.getElementById('clinical_trials').value;
    const createdBy = document.getElementById('createdBy').value;

    const status = 0;
    const indicationName = document.getElementsByClassName('indication_name');
    const systemName = document.getElementsByClassName('system_name');

    const documentTitles = document.getElementsByClassName('document_title');
    const documentLinks = document.getElementsByClassName('document_link');
    const documentYears = document.getElementsByClassName('document_year');
    const documentSources = document.getElementsByClassName('document_source');
    const documentTypes = document.getElementsByClassName('document_type');
    const documentMales = document.getElementsByClassName('document_male');
    const documentFemales = document.getElementsByClassName('document_female');
    const documentCounts = document.getElementsByClassName('document_count');

    const indicationArray = Array.from(indicationName).map(el => el.value);
    const systemArray = Array.from(systemName).map(el => el.value);
    //const drugArray = Array.from(drugName).map(el => el.value);

    const documentTitlesArray = Array.from(documentTitles).map(el => el.value);
    const documentLinksArray = Array.from(documentLinks).map(el => el.value);
    const documentYearsArray = Array.from(documentYears).map(el => el.value);
    const documentSourcesArray = Array.from(documentSources).map(
      el => el.value
    );
    const documentTypesArray = Array.from(documentTypes).map(el => el.value);
    const documentMalesArray = Array.from(documentMales).map(el => el.value);
    const documentFemalesArray = Array.from(documentFemales).map(el => el.value);
    const documentCountsArray = Array.from(documentCounts).map(el => el.value);

    let documentArray = [];

    for (let i = 0; i < documentTitlesArray.length; i++) {
      documentArray.push([
        documentTitlesArray[i],
        documentLinksArray[i],
        documentYearsArray[i],
        documentSourcesArray[i],
        documentTypesArray[i],
        documentMalesArray[i],
        documentFemalesArray[i],
        documentCountsArray[i]
      ]);
    }


    drugForm.reset();

    addDrug(
      drugName,
      chemicalName,
      dosageForm,
      clinicalTrials,
      approvalYear,
      indicationArray,
      companyName,
      className,
      status,
      systemArray,
      documentArray,
      createdBy
    );
  });

// DELEGATION
if (indicationAddBtn)
  indicationAddBtn.addEventListener('click', e => {
    e.preventDefault();
    indications.insertAdjacentHTML(
      'beforeend',
      '<input class="indication_name form__input" type="name" required="">'
    );
  });

if (indicationRemoveBtn)
  indicationRemoveBtn.addEventListener('click', e => {
    e.preventDefault();
    indications.removeChild(indications.lastElementChild);
  });

if (systemAddBtn)
  systemAddBtn.addEventListener('click', e => {
    e.preventDefault();
    systems.insertAdjacentHTML(
      'beforeend',
      '<input class="system_name form__input" type="name" required="">'
    );
  });

if (systemRemoveBtn)
  systemRemoveBtn.addEventListener('click', e => {
    e.preventDefault();
    systems.removeChild(systems.lastElementChild);
  });

  if (drugAddBtn)
  drugAddBtn.addEventListener('click', e => {
    e.preventDefault();
    drugs.insertAdjacentHTML(
      'beforeend',
      '<input class="drug_name form__input" type="name" required="">'
    );
  });

if (drugRemoveBtn)
drugRemoveBtn.addEventListener('click', e => {
    e.preventDefault();
    drugs.removeChild(drugs.lastElementChild);
  });

if (documentAddBtn)
  documentAddBtn.addEventListener('click', e => {
    e.preventDefault();
    documents.insertAdjacentHTML(
      'beforeend',
      `<div class="drug_document"><label class="form__label" for="title">Document title<input class="form__input document_title" type="title" required="required"/><br/><label class="form__label" for="link">Document link</label><input class="form__input document_link" type="link"
      required="required" /><br/><label class="form__label" for="year">Year</label><input class="form__input document_year" type="year" /><br/><label class="form__label" for="source">Source</label><input class="form__input document_source" type="source"
  /><br/><label class="form__label" for="type">Document type</label><select class="form__input document_type" type="type" required="required"><option value="Product Monograph / Prescribing Information">Product Monograph / Prescribing Information</option><option value="Health Technology Assessments">Health Technology Assessments</option><option value="Systematic Reviews / Meta-Analyses">Systematic Reviews / Meta-Analyses</option><option value="Randomized Controlled Trials">Randomized Controlled Trials</option><option value="Economic Studies">Economic Studies</option><option value="Clinical Practice Guidelines">Clinical Practice Guidelines</option></select></label><br><label class="form__label" for="male">Male (%)</label><input class="form__input document_male" type="male" /><br/><label class="form__label" for="female">Female (%)</label><input class="form__input document_female" type="female" /><br/><label class="form__label" for="count">Count</label><input class="form__input document_count" type="count" /><br/></div>`
    );
  });

if (documentRemoveBtn)
  documentRemoveBtn.addEventListener('click', e => {
    e.preventDefault();
    documents.removeChild(documents.lastElementChild);
  });

  try {
    if (tabcontent) {
      for (let l = 0; l < tabcontent.length; l++) {
        if (tablinks[l].className.includes('active')) {
          tabcontent[l].style.display = 'block';
        }
      }
    
      for (let i = 0; i < tablinks.length; i++) {
        tablinks[i].addEventListener('click', e => {
          for (let j = 0; j < tablinks.length; j++) {
            tablinks[j].className = tablinks[j].className.replace(' active', '');
          }
          for (let k = 0; k < tabcontent.length; k++) {
            tabcontent[k].style.display = 'none';
          }
          e.currentTarget.className += ' active';
          for (let l = 0; l < tabcontent.length; l++) {
            if (tablinks[l].className.includes('active')) {
              tabcontent[l].style.display = 'block';
            }
          }
        });
      }
    }
  } catch (error) {
    console.warn("unresolved error at 'tabcontent'... PENDING ...467");
  }

if (loginForm)
  loginForm.addEventListener('submit', e => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    login(email, password);
  });

if (resetForm)
  resetForm.addEventListener('submit', e => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    resetPassword(email);
  });

if (resetFormWithToken)
  resetFormWithToken.addEventListener('submit', e => {
    e.preventDefault();
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('passwordConfirm').value;
    const token = document.getElementById('token').textContent;
    resetPasswordWithToken(password, passwordConfirm, token);
  });

if (searchForm)
  searchForm.addEventListener('submit', e => {
    /*if (event.keyCode === 13) {
      alert('enter');
      const query = document.getElementById('search').value;
      const returnValue = await search(query);
    }*/
    e.preventDefault();

    const plainQuery = document.getElementById('search').value;
    const cleanQuery = DOMPurify.sanitize(plainQuery);

    // check if the 2 query are the same; if not, it contains illegel characters and promp the user to fix it
    if (plainQuery !== cleanQuery) {
      alert("Illegal characters detected. Please modify your search query to avoid special characters such as /\()[]");
      return;
    }

    const encodedCleanQuery = encodeURIComponent(cleanQuery);

    location.assign(`/search/${encodedCleanQuery}`);
  });

/*const handleInput = async (e) => {
    const input = e.target.value;
    if (input.length < 3) {
      document.getElementById('search-results').style.display = 'none';
      return;
    }
  
    // Cancel the previous request
    if (cancelToken) {
      cancelToken.cancel();
    }
  
    // Create a new CancelToken
    cancelToken = axios.CancelToken.source();
  
    try {
      const response = await axios.get(`http://192.168.2.20:3002/api/v1/drugs/autocomplete/${encodeURIComponent(input)}`, {
        cancelToken: cancelToken.token
      });
      updateDropdown(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled due to new input');
      } else {
        console.error('Error fetching data:', error);
      }
    }
};*/

// Define a variable to store the current Axios cancellation token
let cancelToken;

if (searchForm) {
  searchForm.addEventListener('input', debounce(async function(e) {
    const input = e.target.value.trim();
    //console.log('Current input:', input);

    if (input.length < 2) {
      document.getElementById('search-results').style.display = 'none';
      return;
    }

    // Cancel the previous request if it exists
    if (cancelToken) {
      cancelToken.cancel('Cancelled due to new request');
    }

    // Create a new cancel token for the current request
    cancelToken = axios.CancelToken.source();

    try {
      const response = await axios.get(`https://drugdocs.com/api/v1/drugs/autocomplete/${encodeURIComponent(input)}`, {
        cancelToken: cancelToken.token
      });
      //console.log("Response for:", input);
      //console.log(response);

      // Check if the input has not changed during the request
      const currentInput = document.getElementById('search').value.trim();
      if (currentInput !== input) {
        //console.log('Discarding outdated response for:', input);
        return; // Discard this response because it's outdated
      }

      const drugNames = response.data;
      const resultsElement = document.getElementById('search-results');
      resultsElement.innerHTML = '';

      
      // Add the first item to the list
      const firstItem = document.createElement('li');
      //firstItem.textContent = "Ask DRUGDOCS AI";
      firstItem.innerHTML = '<i class="fa fa-pills" style="margin-right: 8px; color: #127e8c;"></i>Ask DRUGDOCS AI';
      firstItem.style.cursor = 'pointer';
      firstItem.style.textDecoration = 'none';
      firstItem.style.color = 'inherit';

      firstItem.addEventListener('click', () => {
        window.location.href = '/drugdocsai'; // Navigate to the desired link
      });

      resultsElement.appendChild(firstItem);

      drugNames.forEach(name => {
        const listItem = document.createElement('li');

        // Encoding the name to safely pass it in the URL
        let urlName = name.replace('®', '');
        let navigateUrl = `/search/${encodeURIComponent(urlName)}`;

        // Set innerHTML to handle HTML such as <sup> tags correctly
        listItem.innerHTML = name.replace('®', '<sup>®</sup>');

        // Set the cursor to pointer to indicate the item is clickable
        listItem.style.cursor = 'pointer';
        listItem.style.textDecoration = 'none';  // Remove underline
        listItem.style.color = 'inherit';  // Inherit text color from parent

        // Adding click event to the entire list item
        listItem.addEventListener('click', () => {
          window.location.href = navigateUrl;  // Navigate when the list item is clicked
        });

        resultsElement.appendChild(listItem);
      });

      resultsElement.style.display = 'block';
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Axios fetch error:', error);
      }
    }
  }, 300)); // Adding debounce function with 300 ms delay

  // Event listener to close the dropdown when clicking outside
  document.addEventListener('click', function(event) {
    const resultsElement = document.getElementById('search-results');
    if (!resultsElement.contains(event.target) && !searchForm.contains(event.target) && !isEventTargetOrParentButton(event.target)) {
      resultsElement.style.display = 'none';
    }
  });

  /**
   * Check if the event target or any of its parents have a name attribute 'overview-banner-button'
   * @param {HTMLElement} element The element to check
   * @return {boolean} True if an element with the name 'overview-banner-button' is found in the ancestry.
   */
  function isEventTargetOrParentButton(element) {
      while (element) {
          if (element.name === 'overview-banner-button') {
              return true;
          }
          element = element.parentElement;
      }
      return false;
  }
}

/**
 * Debounce function to limit the rate of function execution.
 * @param {Function} func The function to debounce.
 * @param {number} wait The number of milliseconds to delay.
 * @return {Function} The debounced function.
 */
function debounce(func, wait) {
  let timeout;
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

// Added by Vlad
if (signupForm)
  signupForm.addEventListener('submit', e => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('passwordConfirm').value;
    const name = document.getElementById('name').value;

    signup(email, name, password, passwordConfirm);
  });

if (logOutBtn) logOutBtn.addEventListener('click', logout);

if (commentBtn)
  commentBtn.addEventListener('submit', e => {
    e.preventDefault();
    const comments = document.getElementById('question').value;
    const drugId = document.getElementById('drugId').value;

    Comment(comments, drugId);
  });

if (userDataForm)
  userDataForm.addEventListener('submit', e => {
    e.preventDefault();
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;

    updateSettings({ name, email }, 'data');
  });

if (userPasswordForm)
  userPasswordForm.addEventListener('submit', async e => {
    e.preventDefault();
    document.querySelector('.btn--save-password').textContent = 'Updating...';

    const passwordCurrent = document.getElementById('password-current').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;
    await updateSettings(
      { passwordCurrent, password, passwordConfirm },
      'password'
    );

    document.querySelector('.btn--save-password').textContent = 'Save password';
    document.getElementById('password-current').value = '';
    document.getElementById('password').value = '';
    document.getElementById('password-confirm').value = '';
  });

const alertMessage = document.querySelector('body').dataset.alert;
if (alertMessage) showAlert('success', alertMessage, 20);

const Client = {
  generateArticle,
  setNavigation,
  retrieveNavigation,
  setSeeMoreContent,
  boldSearchQuery,
  setBannerRadioBtn,
  setTooltipBehavior,
  fetchDrugNews,
  arrangeOrders,
  initializeQueryAssistant,
  configureHeaderAlphabetNav

}

global.Client = Client;