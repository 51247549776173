function setSeeMoreContent() {

    const drugIndicationsDocsSearch = document.querySelectorAll(".drug-indications-docs");
    const toggleIndicationBtnSearch = document.querySelectorAll(".search-show-toggle");

    toggleIndicationBtnSearch.forEach((button) => {

        // get the id-attribute of this button first:
        const btnId = parseInt(button.getAttribute('id'));
    
        button.addEventListener('click', () => {
    
          if (drugIndicationsDocsSearch[btnId].classList.contains("show-more-indications")) {
    
            drugIndicationsDocsSearch[btnId].classList.remove("show-more-indications");
            button.innerHTML = "<i class=search-show-less-arrow></i><span>See Less</span>";
          } else {
    
            // set the webpage position back to the drug name
            const scrollTo = drugIndicationsDocsSearch[btnId];
            scrollTo.scrollIntoView();
            window.scrollBy(0, -200);
    
            drugIndicationsDocsSearch[btnId].classList.add("show-more-indications");
            button.innerHTML = "<i class=search-show-more-arrow></i><span>See More</span>";
          }
        });
      })
}

function boldSearchQuery(searchQuery) {
  let container = document.getElementsByClassName('search-container')[0];

  if (!container) { return; }

  let elements = container.querySelectorAll('p, li');

  for (let i = 0; i < elements.length; i++) {
    let element = elements[i];
    // Create a RegExp object for case-insensitive search
    let searchRegex = new RegExp(searchQuery, 'gi');

    // Check if element's HTML contains the search query using RegExp test method
    if (searchRegex.test(element.innerHTML)) {
      // Create a new span element with bold styling
      let boldSpan = document.createElement('span');
      boldSpan.style.fontWeight = 'bold';
      boldSpan.style.fontSize = '20px';

      // Use the replace method with the searchRegex and a replacement function
      element.innerHTML = element.innerHTML.replace(searchRegex, function(match) {
        // The replacement function uses the actual matched text
        boldSpan.innerHTML = match; // Set the innerHTML to the matched text
        return boldSpan.outerHTML; // Return the outerHTML of the span to replace the match in the original string
      });
    }
  }
}
export {setSeeMoreContent, boldSearchQuery};