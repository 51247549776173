/* eslint-disable */

/* Added by Vlad */
import axios from 'axios';
import { showAlert } from './alerts';

export const changeStatus = async (
  id
) => {

    let Status, drug;

    //GET Drug
    try {
        drug = await axios({
            method: 'GET',
            url: `/api/v1/drugs/${id}`,
        });
  
      } catch (err) {
        showAlert('error', err.response.data.message);
      }

    if (drug.data.data.data.Status == 1) {
        Status = 0;
    }else{
        Status = 1;
    }

    try {
        const res = await axios({
            method: 'PATCH',
            url: `/api/v1/drugs/${id}`,
            data: {
              "Status": Status
          }
        });
  
        if (res.data.status === 'success') {
          //window.setTimeout(() => {
          //  location.assign('/admin');
          //}, 1500);
        }
      } catch (err) {
        showAlert('error', err.response.data.message);
      }
    }
