/**
 * save the navigation path into localStorage for browser to retrieve it later
 * @param {string} category
 */
function setNavigation(category) {
  const allLinks = document.querySelector('.tabcontent').querySelectorAll('a');

  allLinks?.forEach(link => {
    link.addEventListener('click', event => {
      // event.preventDefault();

      const urlArray = window.location.href.split('/');
      const categoryName = urlArray[urlArray.length - 1];

      // set 2 key-value pairs for localStorage: "category" - "categoryName"
      localStorage.setItem('category', category);
      localStorage.setItem('categoryName', categoryName);
      // alert(category + "-" + categoryName);
    });
  });
}

function retrieveNavigation() {
  const bannerNavigation = document.querySelector('.drug-banner-navigation');

  if (
    !localStorage.getItem('category') &&
    !localStorage.getItem('categoryName')
  ) {
    const home = document.createElement('a');
    home.href = '/';
    home.textContent = 'Home';
    home.classList.add("drug-banner-navigation-home");

    bannerNavigation?.insertBefore(home, bannerNavigation.firstChild);
  } else {
    const home = document.createElement('a');
    home.href = '/';
    home.textContent = 'Home';
    home.classList.add("drug-banner-navigation-home");

    const category = localStorage.getItem('category');
    let categoryName = localStorage.getItem('categoryName');

    const categoryTag = document.createElement('a');
    categoryTag.href = category == 'Drug'? `/${category}/alpha/${categoryName}` : `/${category+'/alpha/'+categoryName?.charAt(0)}`;
    categoryTag.textContent = category;

    if (category != 'Drug') {
      const categoryNameTag = document.createElement('a');
      categoryNameTag.href = `/${category}/${categoryName}`;

      categoryNameTag.textContent = categoryName?.replace(/-/g, ' ');

      bannerNavigation?.insertBefore(
        categoryNameTag,
        bannerNavigation.firstChild
      );
    }

    bannerNavigation?.insertBefore(categoryTag, bannerNavigation.firstChild);
    bannerNavigation?.insertBefore(home, bannerNavigation.firstChild);
  }

  let anchorTags = bannerNavigation?.querySelectorAll("a");

  for (let anchor of anchorTags) {

    let slash = document.createTextNode(" / ");
    anchor.parentNode?.insertBefore(slash, anchor.nextSibling);
  }

  // clear localStorage after populating the DOM
  localStorage.removeItem('category');
  localStorage.removeItem('categoryName');
}

export { setNavigation, retrieveNavigation };
