/* eslint-disable */

import axios from 'axios';
import { showAlert } from './alerts';

export const deleteComment = async (id, drugId) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/drugs/${drugId}/comments/${id}`
    });

    if (res.data.status === 'success') {
      //window.setTimeout(() => {
      //  location.assign('/admin');
      //}, 1500);
      showAlert('success', 'Comment deleted!');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
