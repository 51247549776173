
function configureHeaderAlphabetNav(fromRoute, currentAlphabet) {
  let navAlphabetHeader = new NavAlphabetHeader(fromRoute);

  /*const setsLoaded = await navAlphabetHeader.ensureAlphabetSets();
  if (!setsLoaded) {
    alert('Failed to load alphabet sets.');
    return;
  }*/


  navAlphabetHeader.retrieveAlphabetsSetAlphabetColor();
  navAlphabetHeader.setCategoryDropdownHover();

  navAlphabetHeader.setCategoryChangeClick();
  navAlphabetHeader.setIndexClickChange();

  navAlphabetHeader.setMobileCategoryClickDropdown();

  navAlphabetHeader.highlightCurrentActiveAlphabet(currentAlphabet);
}

class NavAlphabetHeader {
  constructor(fromRoute) {
    this.fromRoute = fromRoute;
    this.currentScreenWidth = window.innerWidth;

    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.isMobileDevice = this.currentScreenWidth < 746;

    this.availableNavSelectionAPI = `${window.location.origin}/api/v1/drugs/alphabets`;

    this.navbarAlphabetSelectionPC = document.querySelector('.navbar-alpha');
    this.navbarMobile = document.querySelector('.navbar-mobile-main');
    this.categorySelectionBox = document.querySelector(
      '.navbar-category-change'
    );
    this.isCategorySelectionBoxShown = false;

    this.availableCategories = document.querySelectorAll(
      '.navbar-category-select'
    );
    this.categorySelectionDrug = this.availableCategories[0];
    this.categorySelectionClass = this.availableCategories[1];

    this.allAlphabets = document.querySelectorAll('.nav__alpha');

    this.mobileAlphabetDropdown = document.querySelector(
      '.navbar-mobile-alphabets-dropdown'
    );

    this.categorySelectorHover = document.querySelector('.navbar-hover-select');
    this.triangleIcon = document.querySelector('.navbar-hover-select-triangle');
  }

  setCategoryDropdownHover() {
    this.categorySelectionBox?.addEventListener('mouseleave', event => {
      event.target.style.display = 'none';
      this.triangleIcon.style.transform = '';

      if (this.isSafari == true) {
        this.triangleIcon.style.marginLeft = '';
      }
    });

    this.categorySelectionBox?.addEventListener('mouseenter', event => {
      event.target.style.display = 'block';
      this.triangleIcon.style.transform = 'rotate(180deg)';

      if (this.isSafari == true) {
        this.triangleIcon.style.marginLeft = '5px';
      }
    });

    this.categorySelectorHover?.addEventListener('mouseenter', event => {
      this.categorySelectionBox.style.display = 'block';
    });

    this.categorySelectorHover.addEventListener('mouseenter', event => {
      this.triangleIcon.style.transform = 'rotate(180deg)';

      if (this.isSafari == true) {
        this.triangleIcon.style.marginLeft = '5px';
      }
    });

    this.categorySelectorHover?.addEventListener('mouseleave', event => {
      this.categorySelectionBox.style.display = 'none';

      this.triangleIcon.style.transform = '';

      if (this.isSafari == true) {
        this.triangleIcon.style.marginLeft = '';
      }
    });
  }

  setIndexClickChange() {
    this.allAlphabets.forEach(index => {
      index.addEventListener('click', event => {
        if (this.isMobileDevice == true) {
          return;
        }

        // skip the non-available alphabet category:
        if (event.target.classList.contains('nav-alpha-unavailable')) {
          return;
        }

        const currentCategory = this.categorySelectorHover.textContent.replace(
          /[\s▾]/g,
          ''
        );

        const thisIndex = event.target.textContent;
        this.toggleActiveAlphabetClass(thisIndex); // highlight the active index and attach the green circle 
        this.toggleTabContent(thisIndex, currentCategory);   // switch to the corresponding drug tab below
      });
    });
  }

  /*async ensureAlphabetSets() {
    // Check if alphabet sets are available, otherwise fetch them
    if (!global.Client || !global.Client.alphabetSet || !global.Client.alphabetClassSet) {
      try {
        const response = await fetch(this.availableNavSelectionAPI);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data && data.alphabets && data.alphabetClasses) {
          global.Client = global.Client || {};
          global.Client.alphabetSet = new Set(data.alphabets);
          global.Client.alphabetClassSet = new Set(data.alphabetClasses);
          alert('Fetched alphabet sets successfully.');
        } else {
          throw new Error('API response format is incorrect');
        }
        return true;
      } catch (error) {
        alert('Error fetching alphabet sets: ' + error.message);
        // Provide fallback values if necessary
        global.Client = global.Client || {};
        global.Client.alphabetSet = new Set();
        global.Client.alphabetClassSet = new Set();
        return false;
      }
    } else {
      alert('Alphabet sets already loaded.');
      return true;
    }
  }*/

  setCategoryChangeClick() {
    if (this.fromRoute != 'Current best evidence on prescribed drugs') {
      this.setCategoryChangeClickOtherPage();
      return;
    }

    //const alphabetSet = global.Client.alphabetSet;
    //const alphabetClassSet = global.Client.alphabetClassSet;

    //console.log(alphabetSet);
    //console.log(alphabetClassSet);

    const alphabetSet = new Set([
      "S",
      "T",
      "D",
      "C",
      "L",
      "I",
      "A",
      "E",
      "V",
      "K",
      "X",
      "B",
      "Z",
      "O",
      "N",
      "R",
      "P",
      "F",
      "J",
      "G",
      "M",
      "H",
      "Y",
      "U",
      "W",
      "Q"
  ]);

  const alphabetClassSet = new Set([
    "S",
    "H",
    "P",
    "M",
    "F",
    "K",
    "C",
    "I",
    "A",
    "J",
    "G",
    "R",
    "D",
    "E",
    "N",
    "V",
    "B",
    "U",
    "O",
    "L",
    "T",
    "Q",
    "Z"
]);

    /*if (!alphabetSet || !alphabetClassSet) {
      alert('Alphabet sets are not available.');
      return;
    }*/

    this.availableCategories.forEach(category => {
      category.addEventListener('click', event => {
        const wantedCategory = event.target.textContent;

        if (this.isMobileDevice == true) {
          this.toggleNavbarAlphabetSelectionPC();
        }

        if (this.mobileAlphabetDropdown != null) {
          this.resetMobileDropdown(event.target.textContent);
        }

        this.updateCategorySelectorText(wantedCategory);

        if (wantedCategory == 'DRUG') {
          this.setLeftPositionAndClasses('20%', alphabetSet);
        } else if (wantedCategory == 'CLASS') {
          this.setLeftPositionAndClasses('40%', alphabetClassSet);
        }

        if (this.isMobileDevice) {
          this.setHrefAttributes(wantedCategory, alphabetSet, alphabetClassSet);
        }

        if (!this.isMobileDevice) {
          this.ensureProperState(wantedCategory);
        }

        this.categorySelectionBox.style.display = 'none';
      });
    });
  }

  toggleNavbarAlphabetSelectionPC() {
    if (window.getComputedStyle(this.navbarAlphabetSelectionPC).display == 'none') {
      this.navbarAlphabetSelectionPC.style.display = 'flex';
      //alert('Navbar Alphabet Selection is now visible');
    } else {
      this.navbarAlphabetSelectionPC.style.display = 'none';
    }
  }

  setHrefAttributes(wantedCategory, alphabetSet, alphabetClassSet) {
    const anchors = document.querySelectorAll('a.nav__alpha');
    anchors.forEach(anchor => {
      if ((wantedCategory == 'DRUG' && alphabetSet.has(anchor.textContent)) ||
          (wantedCategory == 'CLASS' && alphabetClassSet.has(anchor.textContent))) {
        anchor.setAttribute('href', `/${wantedCategory.toLowerCase()}/alpha/${anchor.textContent?.toLowerCase()}`);
        //alert(`Set href for ${anchor.textContent}: ${anchor.getAttribute('href')}`);
      } else {
        anchor.removeAttribute('href');
      }
    });

    // Ensure the href attributes are recognized immediately
    anchors.forEach(anchor => {
      if (anchor.getAttribute('href')) {
        anchor.addEventListener('click', event => {
          //alert(`Anchor clicked: ${anchor.getAttribute('href')}`);
        });
      }
    });
  }

  resetMobileDropdown(categoryText) {
    this.mobileAlphabetDropdown.scrollTop = 0;
    const mobileDropdownBtn = document.querySelector('.navbar-mobile-select-dropbtn');
    mobileDropdownBtn.textContent = categoryText;
    this.mobileAlphabetDropdown.classList.add('navbar-mobile-alphabets-dropdown-loading');
    setTimeout(() => {
      this.mobileAlphabetDropdown.classList.remove('navbar-mobile-alphabets-dropdown-loading');
    }, 250);
  }

  updateCategorySelectorText(wantedCategory) {
    const categorySelectorText = document.querySelector('.navbar-hover-select-text');
    categorySelectorText.textContent = wantedCategory + ' ';
  }

  setLeftPositionAndClasses(leftPosition, alphabetSet) {
    if (window.innerWidth < 736) {
      //alert(`Setting left to ${leftPosition}`);
      this.navbarAlphabetSelectionPC.style.left = leftPosition;
    }

    this.updateAlphabetClasses(alphabetSet);
  }

  updateAlphabetClasses(alphabetSet) {
    this.allAlphabets.forEach(alphabet => {
      if (!alphabetSet.has(alphabet.textContent)) {
        alphabet.classList.add('nav-alpha-unavailable');
      } else {
        alphabet.classList.remove('nav-alpha-unavailable');
      }
    });
  }

  ensureProperState(wantedCategory) {
    const allAlphabetsArray = Array.from(this.allAlphabets);
    const activeAlphabetElement = allAlphabetsArray.find(alphabet => 
      alphabet.classList.contains('nav-alpha-active')
    ) || allAlphabetsArray.find(alphabet => 
      !alphabet.classList.contains('nav-alpha-unavailable')
    );

    if (activeAlphabetElement) {
      const thisIndex = activeAlphabetElement.textContent;
      this.toggleActiveAlphabetClass(thisIndex);
      this.toggleTabContent(thisIndex, wantedCategory); // Ensure the current category is used
    //} else {
    //  alert("No available alphabet to switch to.");
    //}
  }
  }

  setCategoryChangeClickOtherPage() {
    this.availableCategories.forEach(category => {
      category.addEventListener('click', event => {
        const listOfIndices = document.querySelector('.navbar-alpha');
  
        if (window.innerWidth < 736) {
          if (window.getComputedStyle(listOfIndices).display == 'none') {
            listOfIndices.style.display = 'flex';
          } else {
            listOfIndices.style.display = 'none';
          }
        }
  
        const mobileAlphabetDropdown = document.querySelector('.navbar-mobile-alphabets-dropdown');
  
        if (mobileAlphabetDropdown != null) {
          mobileAlphabetDropdown.scrollTop = 0;
  
          const mobileDropdownBtn = document.querySelector('.navbar-mobile-select-dropbtn');
          mobileDropdownBtn.textContent = event.target.textContent;
  
          //mobileAlphabetDropdown.classList.add('navbar-mobile-alphabets-dropdown-loading');
  
          //setTimeout(() => {
          //  mobileAlphabetDropdown.classList.remove('navbar-mobile-alphabets-dropdown-loading');
          //}, 250);
        }
  
        const wantedCategory = event.target.textContent;
        const allAlphabets = document.querySelectorAll('.nav__alpha');
        const activeElement = document.querySelector('a.nav-alpha-active');
        const categorySelectorText = document.querySelector('.navbar-hover-select-text');
  
        categorySelectorText.textContent = wantedCategory + ' ';
  
        const getActiveLetter = () => {
          if (activeElement) {
            return activeElement.textContent.trim().toLowerCase();
          }
          return null; // Return null if no active element is found
        };
  
        if (wantedCategory == 'DRUG') {
          // Construct and redirect to URL for DRUG category
          const redirectUrl = `/drug/alpha/${getActiveLetter()}`;
          if (activeElement) {
            window.location.href = redirectUrl;
          }
  
          if (window.innerWidth < 736) {
            listOfIndices.style.left = '20%';
            if (this.fromRoute == 'DRUGDOCS AI') {
              listOfIndices.style.top = '19rem';
            }
          }
  
          allAlphabets.forEach(alphabet => {
            if (!global.Client.alphabetSet.has(alphabet.textContent)) {
              alphabet.classList.add('nav-alpha-unavailable');
              alphabet.removeAttribute('href');
            } else {
              alphabet.classList.remove('nav-alpha-unavailable');
              alphabet.href = `/drug/alpha/${alphabet.textContent.toLocaleLowerCase()}`;
            }
          });
        } else if (wantedCategory == 'CLASS') {
          // Construct and redirect to URL for CLASS category
          const redirectUrl = `/class/alpha/${getActiveLetter()}`;
          if (activeElement) {
            window.location.href = redirectUrl;
          }
  
          if (window.innerWidth < 736) {
            listOfIndices.style.left = '40%';
            if (this.fromRoute == 'DRUGDOCS AI') {
              listOfIndices.style.top = '19rem';
            }
          }
  
          allAlphabets.forEach(alphabet => {
            if (!global.Client.alphabetClassSet.has(alphabet.textContent)) {
              alphabet.classList.add('nav-alpha-unavailable');
              alphabet.removeAttribute('href');
            } else {
              alphabet.classList.remove('nav-alpha-unavailable');
              alphabet.href = `/class/alpha/${alphabet.textContent.toLocaleLowerCase()}`;
            }
          });
        }
  
        // Remove the active class from the currently active element
        if (activeElement) {
          activeElement.classList.remove('nav-alpha-active');
        }
  
        // Add the active class to the newly clicked element
        event.target.classList.add('nav-alpha-active');
  
        this.categorySelectionBox.style.display = 'none';
      });
    });
  }
  

  toggleActiveAlphabetClass(targetAlphabet) {
    const targetAlphabetButton = document.querySelector(
      `a[data-index=${targetAlphabet}]`
    );

    const activeIndex = document.querySelector('.nav-alpha-active');
    activeIndex?.classList.remove('nav-alpha-active');

    targetAlphabetButton?.classList.toggle('nav-alpha-active');

    if (this.currentScreenWidth < 736) {
      setTimeout(() => {
        this.navbarAlphabetSelectionPC.style.display = 'none';
      }, 200);
    }

  }

  toggleTabContent(currentIndex, currentCategory) {
    const tabContents = document.querySelectorAll('.tabContent');
    tabContents.forEach(tabContent => {
      tabContent.style.display = 'none';
    });

    const tabContent = document.querySelector(
      `div.tabContent[data-category="${currentCategory}"]`
    );
    tabContent.style.display = 'block';
    tabContent.scrollTop = 0;

    const targetContainer = document.querySelector(
      `[data-identifier="${currentIndex}"][data-category="${currentCategory}"]`
    );

    const allContainers = document.querySelectorAll('.container');

    allContainers.forEach(container => {
      container.style.display = 'none';
    });
    targetContainer.style.display = 'block';
  }

  setMobileCategoryClickDropdown() {
    const mobileCategoryDropdownBtn = document.querySelector(
      '.navbar-mobile-select-dropbtn'
    );

    const navbarAlphaMain = document.querySelector('.navbar-alpha');

    window.addEventListener('click', event => {
      if (
        navbarAlphaMain.style.display == 'flex' &&
        !event.target.classList.contains('nav__alpha') &&
        !event.target.classList.contains('navbar-category-select')
      ) {
        navbarAlphaMain.style.display = 'none';
      }

      event.stopPropagation();
    });

    mobileCategoryDropdownBtn?.addEventListener('click', event => {
      const categoryDropdownMobile = document.querySelector(
        '.navbar-mobile-category-change'
      );
      const alphabetDropdownMobile = document.querySelector('.navbar-alpha');

      const navLinkDropdownMobile = document.querySelector('.dropdown-content');

      if (navLinkDropdownMobile.style.display == 'block') {
        navLinkDropdownMobile.style.display = 'none';
      }

      if (categoryDropdownMobile.style.display == 'none') {
        categoryDropdownMobile.style.display = 'block';
      } else {
        categoryDropdownMobile.style.display = 'none';
      }

      alphabetDropdownMobile?.classList.toggle(
        'navbar-mobile-alphabets-dropdown'
      );

      event.stopPropagation();
    });
  }

  highlightCurrentActiveAlphabet(currentAlphabet) {
    if ((this.fromRoute != 'All Drugs') && (this.fromRoute != 'All Classes')) {
      return;
    }

    if (this.isMobileDevice == true) {
      return;
    }

    const allAlphabets = document.querySelectorAll('.nav__alpha');

    allAlphabets.forEach(alphabet => {

      if (alphabet.textContent == currentAlphabet) {
        alphabet.classList.add('nav-alpha-active');
        return;
      }
    });
  }

  async retrieveAlphabetsSetAlphabetColor() {
    let alphabetsCategory;

    if (this.fromRoute == 'All Classes') {
      alphabetsCategory = 'CLASS';
    } else {
      alphabetsCategory = 'DRUG';
    }

    //at initial page load, check localStorage cache
    let alphabetSet;
    let alphabetClassSet;

    if (
      localStorage.getItem('alphabetSet') != null &&
      localStorage.getItem('alphabetClassSet') != null
    ) {
      alphabetSet = new Set(JSON.parse(localStorage.getItem('alphabetSet')));
      alphabetClassSet = new Set(
        JSON.parse(localStorage.getItem('alphabetClassSet'))
      );
    } else {
      // attach a loading icon during the load process; remove it after the api call is finished
      // let navbarAlphaMain = document.querySelector(".navbar-alpha");
      //let spinnerContainer = document.createElement('div');
      //spinnerContainer.className = 'navbar-category-loading-state';

      //let spinner = document.createElement('navbar-category-loading-state');
      //spinner.className = 'navbar-category-loading-state-loader';

      //spinnerContainer.appendChild(spinner);
      //this.navbarAlphabetSelectionPC.appendChild(spinnerContainer);

      try {
        let apiResponses = await fetch(this.availableNavSelectionAPI);

        if (apiResponses.ok == true) {
          const response = await apiResponses.json();
          alphabetSet = new Set(response.data.data.uniqueAlphabetArray);
          alphabetClassSet = new Set(
            response.data.data.uniqueAlphabetClassArray
          );

          // at intial loading, store/overweite the above sets into localStorage for later use
          localStorage.setItem(
            'alphabetSet',
            JSON.stringify(Array.from(alphabetSet))
          );
          localStorage.setItem(
            'alphabetClassSet',
            JSON.stringify(Array.from(alphabetClassSet))
          );

          // if ok, remove the loading spinner:
          //this.navbarAlphabetSelectionPC.removeChild(spinnerContainer);
        } else {
          throw Error('Cannot load drug/class info. Try again later');
        }
      } catch (error) {
        //spinner.classList.remove('navbar-category-loading-state-loader');
        //spinner.textContent = error.message;
      }
    }

    global.Client.alphabetSet = alphabetSet;
    global.Client.alphabetClassSet = alphabetClassSet;

    let mapping = { DRUG: 0, CLASS: 1 };

    let alphabetInfoArray = [alphabetSet, alphabetClassSet];

    this.allAlphabets.forEach(letter => {
      let letterText = letter.textContent;

      if (!alphabetInfoArray[mapping[alphabetsCategory]]?.has(letterText)) {
        letter.classList.add('nav-alpha-unavailable');
        letter.removeAttribute('href');
      }
    });
  }
}

export { configureHeaderAlphabetNav };
