/* eslint-disable */

/* Added by Vlad */
import axios from 'axios';
import { showAlert } from './alerts';

export const editUser = async (id) => {
  let active, user;

  //GET Drug
  try {
    user = await axios({
      method: 'GET',
      url: `/api/v1/users/${id}`
    });
  } catch (err) {
    showAlert('error', err.response.data.message);
  }

  if (user.data.data.data.active) {
    active = false;
  } else {
    active = true;
  }

  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/${id}`,
      data: {
        active: active
      }
    });

    if (res.data.status === 'success') {
      //window.setTimeout(() => {
      //  location.assign('/admin');
      //}, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};