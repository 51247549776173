function setTooltipBehavior() {
  document.addEventListener('DOMContentLoaded', function() {
    const infoIconContainers = document.querySelectorAll('.info-icon-container');

    infoIconContainers.forEach(container => {
      const tooltipText = container.querySelector('.tooltip-text');
      let isTooltipVisible = false;
      let hideTimeout;

      const showTooltip = () => {
        clearTimeout(hideTimeout); // Clear any existing timeout
        tooltipText.style.display = 'block';
        isTooltipVisible = true;
      };

      const hideTooltip = () => {
        tooltipText.style.display = 'none';
        isTooltipVisible = false;
      };

      const delayedHideTooltip = () => {
        hideTimeout = setTimeout(() => {
          hideTooltip();
        }, 2000); // 2000 milliseconds = 2 seconds
      };

      container.addEventListener('mouseenter', showTooltip);
      container.addEventListener('mouseleave', delayedHideTooltip);

      // Click event to handle touch devices
      container.addEventListener('click', function() {
        if (isTooltipVisible) {
          hideTooltip();
        } else {
          showTooltip();
        }
      });

      // Hide the tooltip when clicking anywhere outside of it
      document.addEventListener('click', function(e) {
        if (!container.contains(e.target)) {
          hideTooltip();
        }
      });

      // Prevents the document-level click handler from hiding the tooltip immediately
      tooltipText.addEventListener('click', function(e) {
        e.stopPropagation();
      });
    });
  });
}

export { setTooltipBehavior };
