let bannerTexts = [
  '<h3 class="overview-banner-title-more-lineheight">Our Mission: To empower healthcare professionals with the latest prescription drug information to make informed, evidence-based decisions through unbiased resources that support the highest standard of care.</h3>',
  '<h3 class="overview-banner-title-more-lineheight">Our VP & CTO, Dr. Red Thaddeus Miguel, has been honored with the prestigious Forty Under 40 Award! Click <a style="color: white" href="https://drugdocs.com/news/dr.-red-thaddeus-miguel:-recipient-of-the-coveted-forty-under-40-award">here</a> to learn more.</h3>',
  '<h3 class="overview-banner-title-more-lineheight">Transforming healthcare together: American Medical Women\'s Association (AMWA) & DRUGDOCS<sup>®</sup> unite to advance sex-gender awareness in new DRUGDOCS<sup>®</sup> feature. Discover more on our news page.</h3>',
  '<h3 class="overview-banner-title-more-lineheight">DRUGDOCS<sup>®</sup> AI-generated synthesis of the evidence base for each drug is now available for exploration! Learn more on our news page and try it out today.</h3>'
]

function changeBannerText(index) {

  // since banerTexts is self defined, it is safe to use innerHTML
  let bannerTitle = document.querySelector('.overview-banner-title');
  bannerTitle.innerHTML = bannerTexts[index];
}

function setBannerRadioBtn() {
  const radioButtons = document.getElementsByName('overview-banner-button');
  const sliders = document.querySelectorAll('.overview-banner-slide');
  const btnOverlays = document.querySelectorAll(
    '.overview-banner-nav-icon-overlay'
  );

  const allBannerImages = document.querySelectorAll('.overview-banner-image');

  let currentIndex = 1;
  let intervalId;

  let isInitialUserClick = true;

  // set up the initial interval: click the radio btn every 5 to change carousel slide
  intervalId = setInterval(() => {
    radioButtons[currentIndex].click();

    currentIndex = (currentIndex + 1) % radioButtons.length;
  }, 8000);

  for (let i = 0; i < radioButtons.length; i++) {
    radioButtons[i].addEventListener('click', event => {
      sliders.forEach(slider => {
        slider.style.zIndex = '1';
      });

      btnOverlays.forEach(overlay => {
        overlay.classList.remove('overview-banner-nav-icon-overlay-active');
      });

      sliders[i].style.zIndex = '10';
      btnOverlays[i].classList.add('overview-banner-nav-icon-overlay-active');

      const activeImage = document.querySelector(
        '.overview-banner-image-active'
      );
      activeImage?.classList.remove('overview-banner-image-active');
      allBannerImages[i].classList.add('overview-banner-image-active');

      // change bannerText programmatically
      changeBannerText(i);

      // if this action is triggered by user click, reset the interval:
      if (event.isTrusted == true) {
        // clear the previous interval; start a new interval after 5s:
        clearInterval(intervalId);
        isInitialUserClick = true;

        intervalId = setInterval(() => {
          if (isInitialUserClick == true) {
            currentIndex =
              (Array.from(radioButtons).indexOf(event.target) + 1) %
              radioButtons.length;
            isInitialUserClick = false;
          } else {
            currentIndex = (currentIndex + 1) % radioButtons.length;
          }
          radioButtons[currentIndex].click();
        }, 8000);
      }
    });
  }
}

export { setBannerRadioBtn };
