/* eslint-disable */

/* Added by Vlad */
import axios from 'axios';
import { showAlert } from './alerts';

export const editComment = async (id, drugId) => {
  let visible, comment;

  //GET Drug
  try {
    comment = await axios({
      method: 'GET',
      url: `/api/v1/drugs/${drugId}/comments/${id}`
    });
  } catch (err) {
    showAlert('error', err.response.data.message);
  }

  if (comment.data.data.data.visible) {
    visible = false;
  } else {
    visible = true;
  }

  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/drugs/${drugId}/comments/${id}`,
      data: {
        visible: visible
      }
    });

    if (res.data.status === 'success') {
      //window.setTimeout(() => {
      //  location.assign('/admin');
      //}, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
