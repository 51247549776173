
async function fetchDrugNews(drugName) {

  const newsFeature = new NewsFeature();
  newsFeature.setNewsContainerHeightBigScreen();

  newsFeature.fetchNews(drugName);
}

class NewsFeature {
  constructor() {
    // initialize properties:
    this.drugNewsContainer = document.querySelector(
      '.drug-news-item-container'
    );
    this.parentContainer = document.querySelector('.main-drug-info') || document.querySelector('.main-drug-info-mobile');

    this.newsHeaderContainer = document.querySelector(
      '.drug-news-header-container'
    );
    this.mainDrugNews = document.querySelector('.main-drug-news');
    this.indication = document.querySelector('#indication');
    this.mainDrugInfoLeft = document.querySelector('.main-drug-info-left');
    this.currentScreenWidth = window.innerWidth;
    this.drugNewsHeader = document.querySelector('.drug-news-header');
  }

  setNewsContainerHeightBigScreen() {
    const computedStyle = window.getComputedStyle(this.parentContainer);
    const newsContainerHeight = parseFloat(computedStyle.height) * 0.95;
  
    if (this.currentScreenWidth > 480) {
      this.drugNewsContainer.style.height = `${newsContainerHeight}px`;
    }
  }

  async fetchNews(drugName) {
    try {
        const apiResponse = await fetch(
        `${
          window.location.origin
        }/api/v1/drugs/drugNews?keyword=${drugName.toLowerCase()}`,
        {
          headers: {
            'Cache-Control': 'no-cache'
          },
          cache: 'no-cache'
        }
      );
  
      if (apiResponse.status === 404) {
        throw new Error('No current news');
    }

    if (!apiResponse.ok) {
        throw new Error('Server Error');
    }

    const data = await apiResponse.json();

    const publications = data.data;

    const loadingIcon = document.querySelector('div.drug-news-loading-state');

    if (loadingIcon) {
        loadingIcon.remove();
    }

    if (publications.length === 0) {
        throw new Error('No current news');
    }

    publications.forEach(publication => {
        if (publication.date.trim() != '') {
            const newsItem = document.createElement('div');
            const newsLink = document.createElement('a');
            const date = document.createElement('p');

            newsItem.classList.add('drug-news-item');
            newsLink.classList.add('drug-news-title');
            date.classList.add('drug-news-date');

            newsLink.textContent = publication.title;
            newsLink.href = publication.link;
            newsLink.setAttribute('target', '_blank');
            newsLink.setAttribute('rel', 'noopener noreferrer');

            // convert the date format:
            const [year, month, day] = publication.date.split(' ');
            const dateObj = new Date(`${month} ${day}, ${year}`);
            const formattedDate = dateObj.toISOString().split('T')[0];

            date.textContent = ' | ' + formattedDate;

            newsItem.appendChild(newsLink);
            newsItem.appendChild(date);
            this.drugNewsContainer.appendChild(newsItem);
        }
    });
} catch (error) {
    const loadingIcon = document.querySelector('div.drug-news-loading-state');

    if (loadingIcon) {
        loadingIcon.remove();
    }

    const errorMsgText = document.createElement('p');
    errorMsgText.classList.add('drug-news-error-text');
    errorMsgText.textContent = error.message;

    this.drugNewsContainer.classList.add('drug-news-item-container-error');
    this.drugNewsContainer.append(errorMsgText);
}
}
}

export { fetchDrugNews};
