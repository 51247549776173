/* eslint-disable */

/* Added by Vlad */
import axios from 'axios';
import { showAlert } from './alerts';

export const editDrug = async (
  Drug_Name,
  Chemical_Name,
  Dosage_Form,
  Clinical_Trials,
  Approval_Year,
  Indication,
  Company,
  Class,
  System,
  documentArray,
  drugId,
  editedBy
) => {
  let Documents = [];
  for (let i = 0; i < documentArray.length; i++) {
    let Title = documentArray[i][0];
    let Link = documentArray[i][1];
    let Year = documentArray[i][2];
    let Source = documentArray[i][3];
    let Document_Type = documentArray[i][4];
    let Document_Id = documentArray[i][5];
    let Male = documentArray[i][6];
    let Female = documentArray[i][7];
    let Count = documentArray[i][8];
    const Status = 1;
    let res;

  

    try {
      if (Document_Id) {
        res = await axios({
          method: 'PATCH',
          url: `/api/v1/documents/${Document_Id}`,
          data: {
            Title: Title,
            Link: Link,
            Year: Year,
            Source: Source,
            Document_Type: Document_Type,
            Status: Status,
            Male: Male,
            Female: Female,
            Count: Count
          }
        });
      } else {
        res = await axios({
          method: 'POST',
          url: '/api/v1/documents',
          data: {
            Title,
            Link,
            Year,
            Source,
            Document_Type,
            Status,
            Male, 
            Female,
            Count
          }
        });
      }

      if (res.data.status === 'success') {
        Documents.push(res.data.data.data._id);
        //window.setTimeout(() => {
        //  location.assign('/admin');
        //}, 1500);
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
    }
  }

  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/drugs/${drugId}`,
      data: {
        Drug_Name: Drug_Name,
        Chemical_Name: Chemical_Name,
        Dosage_Form: Dosage_Form,
        Clinical_Trials: Clinical_Trials,
        Approval_Year: Approval_Year,
        Indication: Indication,
        Company: Company,
        Class: Class,
        System: System,
        editedBy: editedBy,
        Documents: Documents
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Drug updated!!!');
      //window.setTimeout(() => {
      //  location.assign('/admin');
      //}, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);

    for (let i = 0; i < documentArray.length; i++) {
      try {
        const res = await axios({
          method: 'DELETE',
          url: `/api/v1/documents/${Documents[i]}`
        });
      } catch (err) {
        showAlert('error', err.response.data.message);
      }
    }
  }
};
