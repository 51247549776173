function initializeQueryAssistant() {
  document.addEventListener('DOMContentLoaded', function() {
    const searchButton = document.getElementById('searchButton');
    const responseContainer = document.getElementById('response_id');
    const footnotesContainer = document.getElementById('footnotes');
    //const drugdocsContainer = document.getElementById('drugdocsai'); // New container
    const queryInput = document.getElementById('queryInput');
    const form = searchButton.closest('form'); 
    const loadingGif = '<img src="/img/news-loading-ai.gif" alt="loading GIF" class="loading-gif">';

    if (!searchButton || !responseContainer || !footnotesContainer /*|| !drugdocsContainer*/ || !queryInput || !form) {
      console.error('One or more elements required for the AI query assistant were not found in the DOM.');
      return;
    }

    let finalContentMode = false;
    let footnotes = '';
    //let drugLinks = ''; // New variable

    form.addEventListener('submit', function(event) {
      event.preventDefault(); 

      const query = queryInput.value;
      responseContainer.innerHTML = loadingGif;
      footnotesContainer.innerHTML = '';
      //drugdocsContainer.innerHTML = ''; // Clear drug links
      footnotes = '';
      //drugLinks = ''; // Clear drug links
      finalContentMode = false;

      responseContainer.classList.remove('expanded');
      footnotesContainer.classList.remove('expanded');
      //drugdocsContainer.classList.remove('expanded'); // Remove expanded class

      const eventSource = new EventSource(`/api/v1/ai/query?q=${encodeURIComponent(query)}`);
      let loadingRemoved = false;

      eventSource.onmessage = function(event) {
        let data = event.data;
        console.log("Query: " + query);
        console.log('Raw data received:', data);

        if (data && !data.startsWith(': keep-alive')) {
          console.log("Data received:", data);

          if (!loadingRemoved) {
            responseContainer.innerHTML = '';
            loadingRemoved = true;
          }

          if (data === '[END]') {
            console.log('End of stream received.');
            if (footnotes) {
              console.log('Final footnotes content:', footnotes);
              footnotesContainer.innerHTML = footnotes;
              footnotesContainer.classList.add('expanded'); 
            }
            /*if (drugLinks) {
              console.log('Final drug links content:', drugLinks);
              drugdocsContainer.innerHTML = drugLinks;
              drugdocsContainer.classList.add('expanded'); 
            }*/
            eventSource.close();
            return;
          }

          if (data === '[CLEAR]') {
            console.log('Clear event received. Clearing content.');
            responseContainer.innerHTML = loadingGif;
            responseContainer.classList.remove('expanded');
            footnotesContainer.classList.remove('expanded');
            //drugdocsContainer.classList.remove('expanded'); // Clear expanded class
            finalContentMode = true;
            return;
          }

          if (data === 'FOOTNOTES_START') {
            console.log('Footnotes start received.');
            footnotes = '';  // Ensure footnotes is reset
            return;
          }

          if (data === 'FOOTNOTES_END') {
            console.log('Footnotes end received.');
            console.log('Footnotes accumulated so far:', footnotes);
            footnotesContainer.innerHTML = footnotes;  // Ensure footnotes are appended here
            footnotesContainer.classList.add('expanded'); 
            return;
          }

          // Handle drug links start and end
          /*if (data === 'DRUGDOCS_START') {
            console.log('Drug links start received.');
            drugLinks = '';  // Ensure drug links is reset
            return;
          }

          if (data === 'DRUGDOCS_END') {
            console.log('Drug links end received.');
            console.log('Drug links accumulated so far:', drugLinks);
            drugdocsContainer.innerHTML = drugLinks;  // Ensure drug links are appended here
            drugdocsContainer.classList.add('expanded'); 
            return;
          }*/

          // Adjust condition to match your footnotes structure
          if (data.startsWith('<p><sup>') && !data.includes('href="https://drugdocs.com')) {
            console.log('Footnote data received:', data);
            footnotes += data;
            console.log('Footnotes accumulating:', footnotes);  // Log footnotes as they accumulate
            return;
          }

          // Adjust condition to match your drug links structure
          /*if (data.includes('href="https://drugdocs.com')) {
            console.log('Drug link data received:', data);
            drugLinks += data;
            console.log('Drug links accumulating:', drugLinks);  // Log drug links as they accumulate
            return;
          }*/

          if (finalContentMode) {
            console.log('Processed text with citations received in final content mode:', data);
            responseContainer.innerHTML = data.split('\\n').map(point => `<p>${point.trim()}</p>`).join('');
            console.log('Updated response container with final processed text:', responseContainer.innerHTML);
            responseContainer.classList.add('expanded'); 
            finalContentMode = false;
            return;
          }

          // Handle validation response or regular data
          if (!finalContentMode && !data.startsWith('<p>')) {
            console.log('Validation response received:', data);
            responseContainer.innerHTML += `<p>${data}</p>`;
            responseContainer.classList.add('expanded');
          }
        }
      };

      eventSource.onerror = function(error) {
        console.log('EventSource error:', error);
        eventSource.close();
        responseContainer.innerHTML = '<p>Error loading data...</p>';
      };
    });
  });
}

export { initializeQueryAssistant };
