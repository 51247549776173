/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const contactEmail = async (first_name, email, message) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/email',
      data: {
        first_name,
        email,
        message
      }
    });
    showAlert('success', 'Email sent!');
  } catch (err) {
    showAlert('error', err);
  }
};
