/* eslint-disable */

/* Added by Vlad */
import axios from 'axios';
import { showAlert } from './alerts';

export const addDrug = async (
  Drug_Name,
  Chemical_Name,
  Dosage_Form,
  Clinical_Trials,
  Approval_Year,
  Indication,
  Company,
  Class,
  Status,
  System,
  documentArray,
  createdBy
) => {
  let Documents = [];
  for (let i = 0; i < documentArray.length; i++) {
    let Title = documentArray[i][0];
    let Link = documentArray[i][1];
    let Year = documentArray[i][2];
    let Source = documentArray[i][3];
    let Document_Type = documentArray[i][4];
    let Male = documentArray[i][5];
    let Female = documentArray[i][6];
    let Count = documentArray[i][7];
    const Status = 1;

    try {
      const res = await axios({
        method: 'POST',
        url: '/api/v1/documents',
        data: {
          Title,
          Link,
          Year,
          Source,
          Document_Type,
          Status,
          Male,
          Female,
          Count
        }
      });



      if (res.data.status === 'success') {
        Documents.push(res.data.data.data._id);
        //window.setTimeout(() => {
        //  location.assign('/admin');
        //}, 1500);
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
    }
  }

  try {

    const res = await axios({
      method: 'POST',
      url: '/api/v1/drugs',
      data: {
        Drug_Name,
        Chemical_Name,
        Dosage_Form,
        Clinical_Trials,
        Approval_Year,
        Indication,
        Company,
        Class,
        Status,
        System,
        createdBy,
        Documents
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Drug created!');
      //window.setTimeout(() => {
      //  location.assign('/admin');
      //}, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);

    for (let i = 0; i < documentArray.length; i++) {
      try {
        const res = await axios({
          method: 'DELETE',
          url: `/api/v1/documents/${Documents[i]}`
        });
      } catch (err) {
        showAlert('error', err.response.data.message);
      }
    }
  }
};
