/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const Comment = async (comment, drugId) => {
  let visible = false;
  try {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/drugs/${drugId}/comments`,
      data: {
        comment,
        visible
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Comment submitted for moderation');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
